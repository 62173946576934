.container-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust as needed */
}

.loading-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.lds-ellipsis {
  display: flex; /* Updated to flex container */
  align-items: center; /* Align ellipsis and text horizontally */
  margin-top: 15px; /* Adjust as needed */
  margin-left: -5px;
}

.dot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: #FF9900;
  margin: 0 5px;
  animation: dotAnimation 1s ease-in-out infinite;
}

.loading-text {
  font-size: 16px;
  color: #fff;
}

.loading-image {
  animation: breatheAnimation 1.2s infinite;
  margin-bottom: -10px;
}
.content-container{
	margin-top: 0;
} 
  @keyframes dotAnimation {
	0%, 20%, 50%, 80%, 100% {
	  transform: scale(1);
	}
	40% {
	  transform: scale(1.5);
	}
	60% {
	  transform: scale(1.2);
	}
  }
  
  @keyframes shineAnimation {
	0% {
	  background-position: 100% 0;
	}
	100% {
	  background-position: -100% 0;
	}
  }
  
  @keyframes breatheAnimation {
	0%,
	100% {
	  transform: scale(1);
	}
	50% {
	  transform: scale(1.1);
	}
  }
  
  .lds-ellipsis {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
  }
  .lds-ellipsis div {
	position: absolute;
	top: 33px;
	width: 8px;
	height: 8px;
	border-radius: 50%;
	background: #fff;
	animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }
  .lds-ellipsis div:nth-child(1) {
	left: 8px;
	animation: lds-ellipsis1 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(2) {
	left: 8px;
	animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(3) {
	left: 32px;
	animation: lds-ellipsis2 0.6s infinite;
  }
  .lds-ellipsis div:nth-child(4) {
	left: 56px;
	animation: lds-ellipsis3 0.6s infinite;
  }
  @keyframes lds-ellipsis1 {
	0% {
	  transform: scale(0);
	}
	100% {
	  transform: scale(1);
	}
  }
  @keyframes lds-ellipsis3 {
	0% {
	  transform: scale(1);
	}
	100% {
	  transform: scale(0);
	}
  }
  @keyframes lds-ellipsis2 {
	0% {
	  transform: translate(0, 0);
	}
	100% {
	  transform: translate(24px, 0);
	}
  }
  .loading-text {
	font-size: 20px;
	color: #fff;
	margin-right: 5px;
  }
  .content-container{
	display: flex;
	align-items: center;
  }