@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;500;600;700;800;900&family=Plus+Jakarta+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.navbar{
  background:#121212;
  height: 80px;
  position: relative;
  z-index: 999;
}
.menuNavbar a{
  color: #fff;
  padding: 0 24px !important;
}
.menuNavbar a:hover{
  color: #FBC82F;
}
.btnTalk{
  border-radius:100px;
  background: #FBC82F;
  color: #121212 !important;
  padding: 12px 24px !important;
  font-weight: 600 !important;
  font-family: 'Outfit', sans-serif;
}
#hero{
  background-image: url(../public/assets/img/bgHero.svg);
  background-repeat: no-repeat;
  background-size: cover;
}
.nameDescParent {
  border-bottom: 1px solid #fff;
  margin-bottom: 24px;
  margin-top:100px;
}
.nameHero {
  color: #fff;
  font-weight: 800;
  font-family: 'Outfit', sans-serif;
  font-size: 90px;
  text-align: center;
  line-height: 150%;
  margin-bottom:0;
}
span.navbar-brand:hover {
  cursor: pointer;
}
a.btnTalk.nav-link.active {
  color: #121212 !important;
}
p.descHero {
  color: #fff;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-size: 20px;
  line-height: 175%;
  text-align: center;
  font-weight: 100;
  padding: 0 12% 0 12%;
  margin-bottom:24px;
}
.ageLabel{
  color: #fff;
  font-family: 'Plus Jakarta Sans', sans-serif;
}
a.nav-link.active {
  color: #FBC82E !important;
}
a{
  text-decoration:none !important;
}
.ageLabel label{
  font-size:14px;
}
.ageLabel p {
  font-weight: bold;
}
.ageLabelJob{
  color: #fff;
  font-family: 'Plus Jakarta Sans', sans-serif;
  text-align: center;
}
.ageLabelJob p{
  color: #FBC82F;
  font-family: 'Plus Jakarta Sans', sans-serif;
  font-weight:bold;
}
.ageLabelJob label {
  font-size: 14px;
}
.sosmedContainer {
  width: 40px;
  height: 40px;
  border: 1px solid #989898;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.sosmedHero {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}
.btnScroll {
  width: 48px;
  height: 48px;
  background: #fff;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.scrollContainer {
  display: flex;
  justify-content: center;
  margin-top: 80px;
  margin-bottom: 80px;
}
section#expertise {
  padding-top: 100px;
  padding-bottom: 80px;
  background-image: url(https://res.cloudinary.com/dezm8cnkw/image/upload/v1678781331/bgexpertise_rw4y3r.svg);
  background-size: cover;
    background-position: center;
}
.expertiseParent h1 {
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-weight: 800;
  font-size: 80px;
}
.expertiseParent p {
  color: #CACACA;
  font-family: 'Plus jakarta sans',sans-serif;
  line-height: 175%;
  font-size: 18px;
}
.expertNumber h1 {
  color: #fff;
  font-size: 44px;
  font-weight: 800;
  line-height: 175%;
  text-align: center;
  font-family: 'Outfit', sans-serif;
  margin-bottom: 0;
}
.expertNumber p {
  color: #cacaca;
  font-family: 'Plus Jakarta Sans', sans-serif;
}
.expertNumberParent {
  display: flex;
  justify-content: flex-end;
  gap: 56px;
}
.expertiseCard {
  background: #100D0D;
  border: 1px solid #4F4F4F;
  padding: 24px 24px 38px 24px;
  border-radius: 32px;
}
.expertiseDesc h4 {
  color: #fff;
  font-weight: bold;
  font-family: 'Outfit',sans-serif;
  margin-bottom: 4px;
}
.expertiseDesc p {
  color: #CACACA;
  font-family: 'Plus Jakarta Sans';
  margin-bottom: 0;
}
.expertiseDesc {
  margin-top: 24px;
}
.imgContainer img {
  border-radius: 16px;
}
.workTitle h1 {
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-weight: 800;
  font-size: 80px;
  text-align: center;
}
section#work {
  padding-bottom: 40px;
  background-image: url(https://res.cloudinary.com/dezm8cnkw/image/upload/v1678781331/bgexpertise_rw4y3r.svg);
  background-size: cover;
  background-position: center;
}
.workTitle p {
  color: #CACACA;
  font-family: 'Plus jakarta sans',sans-serif;
  font-size:18px;
  line-height: 175%;
  text-align: center;
  margin-bottom: 0;
}
.mt-80{
  margin-top: 80px !important;
}
.workCard {
  background: #1D1A1A;
  padding: 24px 24px 40px 24px;
  border-radius: 32px;
  transition:0.5s;
  margin:0 24px 40px 24px;
}
.workCard:hover{
  background:#272727;
  cursor: pointer;
}
.workDesc {
  color: #fff;
  margin-top: 24px;
  font-family: 'Plus Jakarta Sans';
}
.workDesc h4 {
  font-family: 'Outfit',sans-serif;
  font-weight: bold;
  line-height: 150%;
  margin-bottom: 2px;
}
a.btnWork {
  color: #121212;
  background: #fbc82f;
  text-decoration: none;
  padding: 16px 32px;
  border-radius: 100px;
  font-family: 'Outfit',sans-serif;
  font-weight: 600;
  font-size: 20px;
}
.btnWorkContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 40px 0;
}
section#blog {
  padding-bottom: 80px;
  padding-top: 40px;
  background-size: cover;
  background-image: url(https://res.cloudinary.com/dezm8cnkw/image/upload/v1678781331/bgexpertise_rw4y3r.svg);
  background-position: center;
}
.blogTitle h1 {
  font-family: 'Outfit', sans-serif;
  color: #fff;
  font-weight: 800;
  font-size: 80px;
}
.blogTitle p {
  color: #CACACA;
  font-family: 'Plus jakarta sans',sans-serif;
  line-height: 175%;
  font-size: 18px;
}
.allStoryBtn {
  color: #fff;
  font-weight: 600;
  display: flex;
  gap: 16px;
  justify-content: flex-end;
}
.blogContainer {
  margin: 0 8px;
}
.blogImg {
  display: block;
  max-height: 210px;
  position: relative;
  height: 210px;
  width: 100%;
}
.blogImg img {
  object-fit: cover;
  height: -webkit-fill-available;
  border-radius: 12px;
}
.blogJudul p {
  color: #fff;
  font-family: 'Plus Jakarta Sans';
  font-weight: bold;
  font-size: 20px;
  margin-top: 16px;
}
.footerContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
footer {
  background-image: url(https://res.cloudinary.com/dezm8cnkw/image/upload/v1678845763/bgFooter_gbb8ci.svg);
  padding-top: 80px;
  background-repeat: no-repeat;
  background-size: cover;
}
.footerTitle h1 {
  color: #fff;
  font-family: 'Outfit',sans-serif;
  font-weight: bold;
  font-size: 48px;
  width: 75%;
  margin-bottom: 20px;
}
.footerTitle {
  color: #fff;
  font-family: 'Plus Jakarta Sans',sans-serif;
  margin-bottom: 0;
}
.footerTitle span {
  color: #FBC82F;
  font-weight: 600;
  text-decoration: underline;
}
.footerBtn {
  display: flex;
  background: #25D366;
  border-radius: 100px;
  padding: 14px 28px;
  align-items: center;
  justify-content: center;
  gap: 8px;
  text-decoration:none;
}
.footerBtn p {
  margin-bottom: 0;
  color: #fff;
  font-size: 20px;
  font-family: 'Outfit',sans-serif;
  font-weight: 600;
}
.credit {
  margin-top: 80px !important;
  text-align: center;
  margin-bottom: 16px;
}
.credit p {
  color: #fff;
  margin-bottom: 0;
  font-size: 14px;
  font-family: 'Outfit',sans-serif;
}
a.blogHref {
  text-decoration: none;
}
.mt-80{
  margin-top : 80px;
}
.portfolioTitleContainer h1 {
  font-family: 'Outfit',sans-serif;
  color: #121212;
  font-weight: 800;
  font-size: 60px;
}
.portfolioTitleContainer p {
  font-size: 20px;
  color: #6D6F73;
  font-family: 'Plus Jakarta Sans',sans-serif;
}
h1.folio {
  display: flex;
  align-items: center;
}
h1.folio::after {
  content: '';
  display: inline-table;
  position: relative;
  width: 110px;
  height: 6px;
  background: #121212;
  margin-left: 24px;
}
.detailRole .roleTitle {
  color: #121212;
}
.detailRole {
  display: flex;
  justify-content: space-between;
  font-family: 'Plus Jakarta Sans',sans-serif;
}
.detailRole p {
  color: #6D6F73;
}
.mr-8{
  margin-right:8px;
}
.bannerPortfolio {
  margin-top: 64px;
}
.projectBackground h3 {
  font-weight: 800;
  font-family: 'Outfit',sans-serif;
  color: #121212;
  margin-bottom: 16px;
}
.projectBackground p {
  color: #6D6F73;
  font-family: 'Plus Jakarta Sans',sans-serif;
  line-height: 175%;
  font-size: 18px;
  margin-bottom: 0;
}
.mt-64{
  margin-top: 64px !important;
}
.mt-40{
  margin-top: 40px !important;
}
.mt-56{
  margin-top: 56px;
}
.mt-48{
  margin-top: 48px;
}
.mb-32{
  margin-bottom: 32px;
}
.imgOverview p {
  font-size: 14px;
  text-align: center;
  font-style: italic;
  font-family: 'Plus Jakarta Sans',sans-serif;
  color: #6D6F73;
  margin-top: 12px;
}
.imgOverview {
  margin-top: 56px;
}
.imgOverview img{
  border-radius: 4px;
}
.separatorDot p {
  font-size: 32px;
  letter-spacing: 40px;
  text-align: center;
  margin-bottom: 0;
  margin-top: 24px;
}
.designProcess h1 {
  font-weight: 800;
  font-family: 'Outfit',sans-serif;
  color: #121212;
  margin-bottom: 16px;
}
.designProcess p {
  color: #6D6F73;
  font-family: 'Plus Jakarta Sans',sans-serif;
  line-height: 175%;
  font-size: 18px;
  margin-bottom: 0;
}
.userJourney h5{
  font-family: 'Outfit',sans-serif;
  color: #121212;
  font-weight: 600;
  margin-bottom: 16px;
}
.userJourneyDesc p {
  font-family: 'Plus Jakarta Sans';
  color: #6D6F73;
  font-size: 18px;
  margin-bottom: 16px;
  line-height: 175%;
}
.userJourney img{
  border-radius: 4px;
}
ul.journeyLi li {
  font-family: 'Plus Jakarta Sans';
  color: #6D6F73;
  font-size: 18px;
  line-height: 175%;
  margin-bottom: 8px;
}
li.solusi {
  font-style: italic;
  font-weight: 600;
  list-style: none;
}
.userJourney p {
  font-family: 'Plus Jakarta Sans';
  color: #6D6F73;
  font-size: 18px;
  margin-bottom: 16px;
  line-height: 175%;
}
p.imgDescPortfo {
  font-size: 14px;
  text-align: center;
  font-style: italic;
  font-family: 'Plus Jakarta Sans',sans-serif;
  color: #6D6F73;
  margin-top: 12px;
}
.finalDesignContainer h1 {
  font-weight: 800;
  font-family: 'Outfit',sans-serif;
  color: #121212;
  margin-bottom: 24px;
  margin-top: 64px;
}
p.designSystemTitle {
  font-weight: 800;
  font-family: 'Outfit',sans-serif;
  color: #121212;
  margin-top: 64px;
  text-align: center;
  font-size: 40px;
}
.designSystemContainer h4 {
  color: #121212;
  font-family: 'Outfit',sans-serif;
  font-weight: bold;
  border-left: 4px solid #FBC82F;
  padding-left: 16px;
}
.designSystemContainer {
  border-bottom: 1px solid #EBEBEB;
  padding-bottom: 24px;
  margin-bottom: 24px;
}
.designSystemParent{
  margin-top: 80px;
}
.designSystemParentTwo {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  flex-direction: row;
  gap: 80px;
}
.bg-lightYellow{
  background: #FFFCF1;
}
.summaryPortfo {
  margin: 64px 0;
}
.summaryPortfo h3 {
  font-weight: bold;
  font-family: 'Outfit',sans-serif;
  color: #121212;
  margin-bottom: 16px;
}
.summaryPortfo p {
  font-family: 'Plus Jakarta Sans',sans-serif;
  color: #6D6F73;
  line-height: 175%;
  font-size: 18px;
  margin-bottom: 0;
}



@media (max-width: 800px) {
  .designSystemParentTwo {
    flex-direction: column;
    gap:0;
  }
  .col-role{
    margin-left:0 !important;
  }
  .nameHero{
    font-size:60px;
    margin-bottom: 20px;
  }
  .nameDescParent{
    margin-top:30px;
  }
  button.navbar-toggler.collapsed {
    background: #FBC82F;
  }
  button.navbar-toggler {
    background: #FBC82F;
  }
  .navbar-collapse.collapse.show {
    margin-top: 20px;
  }
  .navbar-collapse.collapse.show div.menuNavbar {
    background: #fbc82f;
  }
  .ageLabel {
    text-align: center;
  }
  .sosmedHero {
    justify-content: center;
    margin-top: 16px;
  }
  p.descHero{
    padding: 0 5% 0 5%;
  }
  .expertiseParent h1{
    font-size: 60px;
  }
  .expertNumber p{
    text-align:center;
  }
  .expertNumber h1{
    font-size:32px;
  }
  .expertNumberParent{
    gap: 0;
    justify-content: space-between;
  }
  .expertiseCard {
    margin-bottom: 24px;
  }
  .workTitle h1{
    font-size:60px;
  }
  .blogTitle h1{
    font-size:60px;
  }
  .allStoryBtn {
    justify-content: flex-start;
    margin-bottom: 24px;
  }
  .blogContainer {
    margin-bottom: 40px;
  }
  section#blog {
    padding-bottom: 0;
  }
  .footerTitle h1 {
    width: 100%;
  }
  a.footerBtn {
    margin-top: 24px;
    width: 100%;
  }
}

